import React from "react";
import { withRouter, Link } from "react-router-dom";
import Email from './Email';

function Misc() {
    return (
        <div className="misc">
            <p className="center">
            <Link to="/diecast">
                    <img src="./images/empt.jpg" alt="" />
                </Link>
            <Link to="/update">
                    <img src="./images/empt.jpg" alt="" />
                </Link>
                <a href="./files/rld-thug.rar">
                    <img src="./images/empt.jpg" alt="" />
                </a>
            </p>
            <div id="email">
                <Email />
            </div>
        </div>
    );
}

export default withRouter(Misc);