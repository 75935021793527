import React from "react";
import { withRouter } from "react-router-dom";
import Email from './Email';

function Industrial() {
    return (
        <div className="industrial">
            <div id="VideoContainer">
                <p>To save a video: Right click -> Save Link As</p>
                <div className="movieSet">
                    <p><a href="./files/industest.wmv">Jason's Edit </a></p>
                </div>
                <div className="movieSet">
                    <p className="center">My Edit</p>
                    <p><a href="./files/industrial.wmv"><img src="images/industrial.jpg" alt="" /></a></p>
                </div>
            </div>  
            <div id="email">
                <Email />
            </div>
        </div>
    );
}

export default withRouter(Industrial);