import React from "react";
import { Link, withRouter } from "react-router-dom";

function Navigation(props) {
  return (
    <div className="navigation">
      <p className="logo">
        <Link className="nav-link" to="/home">
          <img src="./images/mainlogo.jpg" alt="" />
        </Link>
      </p>
    </div>
  );
}

export default withRouter(Navigation);