import React from "react";
import { Link, withRouter } from "react-router-dom";
import Email from './Email';

function Home() {
  return (
    <div className="home">
      <div className="container">
        <div id="IconContainer">
          <div id="pics">
            <Link className="nav-link" to="/pics">
              <img src="./images/pics1.jpg" alt="" />
            </Link>
          </div>
          <div id="video">
            <Link to="/video">
              <img src="./images/video1.jpg" alt="" />
            </Link>
          </div>
          <div id="misc">
            <Link to="/misc">
              <img src="./images/misc1.jpg" alt="" />
            </Link>
          </div>
          <Email />
        </div>
      </div>
    </div>
  );
}

export default withRouter(Home);