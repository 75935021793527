import React from "react";
import { withRouter } from "react-router-dom";
import Navigation from './Navigation';

function Start() {
    return (
        <div className="misc">
            {/* <Navigation /> */}
        </div>
    );
}

export default withRouter(Start);