import React from "react";
import { withRouter } from "react-router-dom";
// import PropTypes from "prop-types";
// import axios from "axios";

const Updates = (props) => {
    return (
      <div className="contact-form">
        
        <form action="./diecast/api/create.php" method="POST" encType="multipart/form-data">
            <p>Your make: <input type="text" name="make" /></p>
            <p>Your model: <input type="text" name="model" /></p>
            <p>Your countryOfOrigin: <input type="text" name="countryOfOrigin" /></p>
            <p>Your color: <input type="text" name="color" /></p>
            <p>Your horsepower: <input type="text" name="horsepower" /></p>
            <p>Your torque: <input type="text" name="torque" /></p>
            <p>Your zeroToSixty: <input type="text" name="zeroToSixty" /></p>
            <p>Your topSpeed: <input type="text" name="topSpeed" /></p>
            <p>Your year: <input type="text" name="year" /></p>
            <p>Your brand: <input type="text" name="brand" /></p>
            <p>Your whoWhen: <input type="text" name="whoWhen" /></p>
            <p>Your receivedDate: <input type="text" name="receivedDate" /></p>
            <p>Your category: <input type="text" name="category" /></p>
            <p>Your serialNumber: <input type="text" name="serialNumber" /></p>
            <p>Your cost: <input type="text" name="cost" /></p>
            <p>Your name: <input type="text" name="name" /></p>
            {/* <p>Your thumbnails: <input type="text" name="thumbnails" /></p> */}
            <p>Your thumbnails: <input type="file" name="thumbnails" id="fileSelect" /></p>
            <p><input type="submit" name="upload" /></p>
        </form>
      </div>
    );
}

  
export default withRouter(Updates);
