import React from "react";


function Email() {
    return (
        <div className="email">
            <div id="email">
                <a href="mailto:bustinthings@hotmail.com">
                    <img src="./images/email0.jpg" width="167" height="107" border="0" align="bottom" alt="" />
                </a>
            </div>
        </div>
    );
}

export default Email;