import React from "react";
import { Link, withRouter } from "react-router-dom";
import Email from './Email';

function Video() {
    return (
        <div className="video">
            <div id="VideoContainer">
                <p>To save a video: Right click -> Save Link As</p>
                <div className="movieSet">
                    <p><strong>Way Past</strong></p>
                    <p><a href="./files/executor.mpg">Executer</a></p>
                    <p><a href="./files/bootleg gunplay.mpg">Garage</a></p>
                </div>
                <div className="movieSet">
                    <p><strong>Kinda Current Projects</strong></p>
                    <p><Link className="nav-link" to="/gundown">MP5 Gundown</Link></p>
                    <p><Link className="nav-link" to="/industrial">Industrial Chase</Link></p>
                </div>
                <div className="movieSet">
                    <p><strong>Others</strong></p>
                    <p><a href="./files/lasthack small.wmv">The last Hack</a></p>
                </div>
            </div>
            <div id="email">
                <Email />
            </div>
        </div>
    );
}

export default withRouter(Video);