import React from "react";
import { Link, withRouter } from "react-router-dom";
import Email from './Email';

function CP() {
    return (
        <div>
            <div id="IconContainer">
                <div id="caddyImgSet"><Link to="/cp1">Set 1</Link></div>
                <div id="caddyImgSet"><Link to="/cp2">Set 2</Link></div>
                <div id="caddyImgSet"><Link to="/cp3">Set 3</Link></div>
                <div id="caddyImgSet"><Link to="/cp4">Set 4</Link></div>
                <div id="email">
                    <Email />
                </div>
                <div id="caddyTreasureHunt">
                    <a href="./files/STS%20hunt.rar">
                        <img src="./images/caddys.jpg" alt="" />
                    </a>
                </div>
            </div>
        </div>
        );
}

export default withRouter(CP);