import React from "react";
import { Link, withRouter } from "react-router-dom";
import Email from './Email';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const slideImages = [
    './files/stsi/931.jpg',
    './files/stsi/930.jpg',
    './files/stsi/932.jpg',
    './files/stsi/935.jpg',
    './files/stsi/934.jpg',
    './files/stsi/933.jpg',
];

function CP2() {
    return (
        <div className="cp2">
            <div id="CaddyImgContainer">
                <div className="slide-container">
                    <Slide easing="ease">
                    {slideImages.map((each, index) => (
                        <div key={index} style={{width: "100%"}}>
                            <img style={{ objectFit: "cover", width: "100%" }} alt="" src={each} />
                            <span>{index + 1} of {slideImages.length}</span>
                        </div>
                    ))}
                    </Slide>
                </div>
                <p className="center"><Link to="/cp">Go Back</Link></p>
            </div>
            <div id="email">
                <Email />
            </div>
        </div>
    );
}

export default withRouter(CP2);