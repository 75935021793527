import React from "react";
import { Link, withRouter } from "react-router-dom";
import Email from './Email';

function Pics() {
    return (
        <div className="diecast">
            <div className="container">
                <div id="IconContainer">
                    <div id="warped">
                        <a href="./files/Warped.rar">
                            <img src="./images/Warped.gif" alt="" />
                        </a>
                    </div>
                    <div id="caddy">
                        <Link to="/cp">
                            <img src="./images/crest.jpg" alt="" />
                        </Link>
                    </div>
                    <div id="caddy">
                        <a href="diecast/index.php">
                            <img src="./images/crest.jpg" alt="" />
                        </a>
                    </div>
                    <Email />
                </div>
            </div>
        </div>
    );
}

export default withRouter(Pics);