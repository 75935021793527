import React from "react";
import { withRouter } from "react-router-dom";
import Email from './Email';

function Gundown() {
    return (
        <div className="gundown">
            <p className="center"><a href="./files/mp5.wmv">Joe's Edit</a></p>
            <p className="center"><a href="./files/mp5ish.wmv">My Edit</a></p>
            <div id="email">
                <Email />
            </div>
        </div>
    );
}

export default withRouter(Gundown);