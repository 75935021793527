import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link, withRouter } from "react-router-dom";

function Diecast(props) {
  let [responseData, setResponseData] = useState([]);
  // let url = `http://localhost/bustinthings/src/diecast/api/dapi.php`;
  // let url = `http://bustinthings.com/diecast/api/dapi.php`;
  useEffect(() => {
    axios.get(
      `./diecast/api/dapi.php`,
      {
        method: "GET"
      }
    )
    .then((response) => {
      setResponseData(response.data);
    })
    .catch((error) => {
      console.log(error);
    })
  }, []);

  return (
    <div className="diecast2">
      <table>
        <thead>
          <tr>
              <th>Name</th>
            </tr>
        </thead>
        <tbody>
          {responseData.map((car, index) => (
            <tr key={index}>
                  <td>{index+1}) <Link to={{
    pathname: `/details/${car.id}`,
    id: car.id 
  }}>{ car.name }</Link></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default withRouter(Diecast);