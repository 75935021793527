import React from "react";
import { withRouter } from "react-router-dom";

function PageNotFound(props, { location }) {
    console.log('props=== ', props);
    console.log('location=== ', location);
    // location: { pathname: 'string' };

    return (
      <div className="navigation">
        <p>
    Page not found - the path, {props.location.pathname}, did not match
    any React Router routes.
  </p>
      </div>
    );
  }
  
  export default withRouter(PageNotFound);