import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Home, Diecast, Navigation, Pics, Video, CP, CP1, CP2, CP3, CP4, Misc, Industrial, Gundown, Start, DiecastDetails, Updates } from "./components";

function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <Switch>
          <Route path="/" exact component={() => <Start />} />
          <Route path="/home" exact component={() => <Home />} />
          <Route path="/diecast" exact component={() => <Diecast />} />
          <Route path="/pics" exact component={() => <Pics />} />
          <Route path="/video" exact component={() => <Video />} />
          <Route path="/cp" exact component={() => <CP />} />
          <Route path="/cp1" exact component={() => <CP1 />} />
          <Route path="/cp2" exact component={() => <CP2 />} />
          <Route path="/cp3" exact component={() => <CP3 />} />
          <Route path="/cp4" exact component={() => <CP4 />} />
          <Route path="/misc" exact component={() => <Misc />} />
          <Route path="/gundown" exact component={() => <Gundown />} />
          <Route path="/industrial" exact component={() => <Industrial />} />
          <Route path="/update" exact component={() => <Updates />} />
          <Route path="/details/:id" render={(props) => <DiecastDetails {...props} />} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;