import React from "react";
import { Link, withRouter } from "react-router-dom";
import Email from './Email';

function CP3() {
    return (
        <div className="cp3">
            <div id="CaddyImgContainer">
                <p className="center"><Link to="/cp">Go Back</Link></p>
            </div>
            <div id="email">
                <Email />
            </div>
        </div>
    );
}

export default withRouter(CP3);