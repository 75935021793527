import React, { useState, useEffect } from "react";
import axios from 'axios';
import { withRouter } from "react-router-dom";

function DiecastDetails(props) {
    let [responseData, setResponseData] = useState({});
// let url = `http://localhost/bustinthings/src/diecast/api/dapi.php`;
  // let url = `http://bustinthings.com/diecast/api/dapi.php`;
    useEffect(() => {
      axios.get(
        `./diecast/api/dapi.php?id=${props.match.params.id}`,
        {
          method: "GET"
        }
      )
      .then((response) => {
        setResponseData(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
    }, []);
    console.log('responseData===', responseData);
  return (
    <div className="diecast">
          <div id='carContainer'> 
              {
                  Object.keys(responseData).map((key, i) => (
                    <p key={i}>
                      <span>{key}: {responseData[key]}</span>
                    </p>
                  ))
              }
				
			</div>
    </div>
  );
}

export default withRouter(DiecastDetails);