import React from "react";
import { Link, withRouter } from "react-router-dom";
import Email from './Email';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const slideImages = [
    './files/STS/002.jpg',
    './files/STS/003.jpg',
    './files/STS/001.jpg',
    './files/STS/012.jpg',
    './files/STS/T013.jpg',
    './files/STS/T005.jpg',
    './files/STS/T004.jpg',
    './files/STS/T011.jpg',
    './files/STS/T006.jpg',
    './files/STS/T007.jpg',
    './files/STS/T008.jpg',
    './files/STS/T009.jpg',
    './files/STS/T010.jpg',
];

function CP1() {  
    return (
        <div className="cp1">
            <div className="slide-container">
                <Slide easing="ease">
                {slideImages.map((each, index) => (
                    <div key={index} style={{ width: "100%" }}>
                        <img style={{ objectFit: "cover", width: "100%" }} alt="" src={each} />
                        <span>{index + 1} of {slideImages.length}</span>
                    </div>
                ))}
                </Slide>
            </div>
            <p className="center"><Link to="/cp">Go Back</Link></p>
            <div id="email">
                <Email />
            </div>
        </div>
    );
}

export default withRouter(CP1);